import React from "react";
import "./services.css";
import { HiOutlineCheck } from "react-icons/hi";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Full Stack Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Software development from conception to deployment</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Design client-side and server-side architecture</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Troubleshoot, debug and upgrade software</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Testing to ensure responsiveness and efficiency</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Write well structured technical documentation</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Applications with mobile responsive design</p>
            </li>
          </ul>
        </article>
        {/* END OF FULL STACK DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>Blockchain Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Design, develop, and test blockchain technologies</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Integrate current technologies to adaption of blockchain</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Develop smart contracts with upmost security</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Ensure efficiency in code for network fees</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Document development and comply with best practices</p>
            </li>
          </ul>
        </article>
        {/* END OF BLOCKCHAIN DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>Networking</h3>
          </div>
          <ul className="service__list">
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Manage, service and maintain multiple servers</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Troubleshoot and resolve networking or security issues</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Configure and install various network devices</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Monitor performance, system availability and reliability</p>
            </li>
            <li>
              <HiOutlineCheck className="service__list-icon" />
              <p>Create effective redunancy in data storage</p>
            </li>
          </ul>
        </article>
        {/* END OF NETWORKING */}
      </div>
    </section>
  );
};

export default Services;
