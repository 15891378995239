import React from "react";
import "./about.css";
import IMG1 from "../../assets/IMG_3269.jpg";
import { FaAward } from "react-icons/fa";
import { VscFolderLibrary } from "react-icons/vsc";
import { FiUsers } from "react-icons/fi";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={IMG1} alt="me" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>2+ Years working</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>10+ Clients</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>20+ completed projects</small>
            </article>
          </div>

          <p>
            Hello! I'm Danny,
            <br></br>
            <br></br>
            Software Engineer, Developer, Blockchain Enthusiast, Traveler and
            Man of culture.
            <br></br>
            <br></br>A natural affinity for computers has sparked a pursuit into
            the tech industry with aspirations of creating applications and
            software to help further the advancement of innovation in
            technology.
            <br></br>
            <br></br>
            Most of my foundational programming skills are from a coding
            bootcamp with Generation USA. My hunger to flexibly explore practical
            solutions in a creative manner led me down the self-taught path. As
            I continue to build new projects and revise old ones, I am always
            seeking to improve my programming toolbelt. Currently, I am in
            pursuit of finishing my Associate in Science in Networking Services
            Technology - Enterprise Cloud Computing degree at Miami-Dade
            College.
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Chat!
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
